.content-title-wrap {
    display: flex;
}

.stat-boxes-wrap {
    display: flex;
    flex-direction: row;
    padding: 40px 10px 20px 10px 
}

.stat-boxes {
    width: 24%;
    height: 100px;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    padding: 10px 10px 10px 20px;
    align-items: center;
    position: relative;
    margin-right: 7%;
    cursor: pointer;
}

.stat-boxes h1,h2 {
    padding: 2px;
    opacity: 0.8;
    flex: 1;
    text-align: left
}

.stat-boxes h1 {
    text-align: center;
    font-size: 40px
}

.notification-icon {
    width: 15px;
    position: absolute;
    top: 10px;
    right: 10px;
    opacity: 0.3;
}

.row-title {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    width: 88%;
    margin-left: 1%;
    margin-top: 20px;
    border-radius: 5px
}

.list-container {
    display: flex;
    justify-content: flex-start;
    padding: 10px;
    width: 92%;
    margin-top: 0;
    border-radius: 5px;
    flex-direction: column
    
}

.list-contents {
    display: flex;
    list-style: none;
    padding-left: 20px;
    margin: 10px 0 0 0;
    border-bottom: solid 1px #ebebeb;
    font-size: 13px
}

.list-contents-title {
   border-radius: 5px;
   background-color: #1374ec;
   color: #fff;
   display: flex;
   list-style: none;
   padding-left: 20px;
   margin: 0
}

.list-items-title, .list-items {
    width: 20%;
    height: 50px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.list-items-title {
    color: #fff;
   font-weight: 600;
   height: 50px;
   
}

.list-items {
   color: #555;
   padding-bottom: 10px
}

.list-items button, .savings-confirm-button {
    width: 130px;
    height:35px;
    cursor: pointer;
    border-radius: 5px;
    outline: none
 }

 .savings-confirm-button {
    margin-bottom: 0;
    background: #f33975;
    color: #eee;
    width: 100px;
    margin-left: 30px;
    opacity: 1;
    border: none;
 }
 
 .search-input-wrapper {
     display: flex;
     align-items: center
 }

 .search-input-wrapper input {
     outline: none;
     width: 300px;
     height: 30px;
     border: none;
     border-bottom: solid 1px #999;
     padding: 5px;
     font-size: 15px
 }

 .search-input-wrapper img {
    width: 20px;
    margin-left: 5px;
    cursor: pointer;
}

