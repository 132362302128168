.email-data-container {
   width: 60%;
   margin: 0 auto;
   padding-top: 50px;
   padding-right: 80px;
}

.email-data-rows {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: rgb(252, 228, 253);
    padding: 10px 30px;
    border-radius: 10px;
    margin-bottom: 20px;
}

.email-data-rows a {
    color: #fff;
}

.email-data-rows h5{
    color: rgb(23, 23, 61);
    letter-spacing: 2px;
}

.email-data-rows-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.email-data-rows-btn p {
    width: 150px;
    height: 40px;
    background-color: rgb(31, 31, 77);
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    border-radius: 5px;
    cursor: pointer;
 }

.email-data-rows-btn img {
   width: 30px;
   object-fit: contain;
   margin-left: 10px;
   cursor: pointer;
}