.custom-select-container {
    display: flex;
    flex-direction: row;
    border: 1px solid rgb(227, 228, 250);
    width: 180px;
    padding: 5px 20px 5px 25px;
    height: 25px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 5px;
}

.custom-select-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.custom-select-wrapper h5{
    margin: 0;
    color: rgb(68, 68, 87);
}

.custom-dropdown-wrapper {
    display: flex;
    flex-direction: column;
    border: 1px solid rgb(227, 228, 248);
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
}

.custom-dropdown-wrapper p {
    color: rgb(68, 68, 87);
    background-color: rgb(247, 247, 247);
    width: 100%;
    text-align: center;
    font-size: 13px;
    padding: 10px 0;
    margin-bottom: 2px;
    font-weight: 600;
    cursor: pointer;
}

.custom-dropdown-wrapper p:hover {
    background-color: rgb(255, 255, 255);

}