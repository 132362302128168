.login-page-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center
}

.logo-div {
    padding: 50px
}

.login-form-wrapper {
    width: 30%;
    border: solid 1px #eee;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #eee;
    padding-top: 40px
}

.login-form-wrapper input {
    width: 250px;
    height: 45px;
    border: solid 1px #999;
    margin-bottom: 20px;
    outline: none;
    padding-left: 10px;
    font-size: 17px;
    border-radius: 3px
}

.login-form-wrapper button {
    width: 250px;
    padding: 10px;
    height: 45px;
    outline: none;
    border-radius: 5px;
    font-size: 17px;
    margin-bottom: 40px;
    cursor: pointer;
    background-color: #11184A;
    color: #fff;
    outline: none;
    border: none;
}

.login-form-wrapper button:hover {
    background-color: #f33975;
}