.header-wrapper {
    display: flex;
    background-color: #eee;   
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 90px;
    justify-content: space-between;
    position: fixed;
    z-index: 1000;
}

.logo-wrapper {
   margin-left: 70px
}

.logo-image {
    width: 50px;
}

.account-info-wrapper {
    margin-right: 50px;
    text-align: left
}

.header-right-side-wrapper {
    display: flex;
    flex-direction: 'row'
}

.transfer-rates-wrapper {
    margin-right: 60px
}

.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 30px;
    height: 30px;
  }
  .lds-ellipsis div {
    margin-top: 5px;
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #f1cffb;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }