.sidebar-wrapper {
   display: flex;
   width: 18%;
   background: #eee;
   height: 100vh;
   position: fixed;
   align-items: center;
   flex-direction: column
}

.menu-wrapper {
   margin-top: 30px;
   height: 100%;
   display: flex;
   flex-direction: column;
   
}

.menu-item {
   width: 180px;
   height: 40px;
   margin-bottom: 20px;
   border-radius: 3px;
   border: solid 1px #ccc;
   outline: none;
   cursor: pointer;
   font-size: 14px;
   position: relative;
}

.menu-item-counter {
   position: absolute;
   top: -15px;
   right: -15px;
   background-color: #f33975;
   color: #fff;
   width: 30px;
   height: 30px;
   border-radius: 50px;
   display: flex;
   justify-content: center;
   align-items: center;
   font-size: 10px;
   font-weight: 600;
}

.menu-item:hover {
   background-color: #11184A;
   color: #eee
}

.menu-bottom-div {
   position: absolute;
   z-index: 10000;
   bottom: 17%;
}

.menu-bottom-div button {
   margin-bottom: 0;
   background: #f33975;
   color: #eee;
   width: 100px;
   margin-left: 30px;
   opacity: 1;
   border: none;
}

.menu-bottom-div button:hover {
   opacity: 1;
   background: #11184A;
   color: #fff;
}

.analytics-menu-btn {
   background-color: #fff;
   font-weight: 700;
   color: rgb(19, 19, 68);
}

.savings-menu-btn {
   background-color: rgb(238, 246, 253);
   font-weight: 700;
   color: rgb(19, 19, 68);
}

.email-menu-btn {
   background-color: rgb(255, 255, 255);
   font-weight: 700;
   color: rgb(19, 19, 68);
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
}

.email-menu-btn img{
   width: 20px;
   object-fit: contain;
   margin-left: 5px;
}

.animated-gradient {
   background: repeating-linear-gradient(to right, rgb(252, 249, 250) 0%, rgb(157, 157, 243) 50%, rgb(240, 145, 145) 100%);
   width: 180px;
   background-size: 200% auto;
   background-position: 0 100%;
   animation: gradient 2s infinite;
   animation-fill-mode: both;
   animation-timing-function: linear;
   height: 5px;
   opacity: 0.5;
 }

 .animated-gradient-download {
   background: repeating-linear-gradient(to right, rgb(252, 249, 250) 0%, rgb(157, 157, 243) 50%, rgb(240, 145, 145) 100%);
   width: 100%;
   background-size: 200% auto;
   background-position: 0 100%;
   animation: gradient 2s infinite;
   animation-fill-mode: both;
   animation-timing-function: linear;
   height: 5px;
   opacity: 0.5;
   margin-bottom: 5px;
 }

 .email-icon-wrapper {
    position: absolute;
    bottom: 200px;
    border-radius: 50px;
 }

 .email-icon-wrapper:hover {
   background-color: rgb(255, 255, 255);
}

 .email-icon-wrapper img {
   width: 50px;
   cursor: pointer;
}
 
 @keyframes gradient { 
   0%   { background-position: 0 0; }
   100% { background-position: -200% 0; }
 }


