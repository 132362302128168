.user-content-wrapper {
   display: flex;
   
}

.content-title-wrap {
    justify-content: space-between;
    align-items: center
}

.details-container {
    display: flex;
    margin-top: 20px
}

.user-details-wrapper {
    width: 50%;
    margin-bottom: 50px
}

.user-data-info {
    display: flex;
    padding: 10px 10px 20px 10px ;
    border-bottom: solid 1px #e7e7e7;
    
}

.user-data-info .info-title {
    margin-right: 30px;
    width: 150px
}

.user-data-info .info-response {
    color: #999
}

.user-identity-card-wrapper {
    padding: 50px
}

.user-identity-card-wrapper {
    object-fit: contain;
    
}

.reverse-trans-btn {
    border: none;
    padding: 10px 20px;
    font-size: 12px;
    background-color: rgb(255, 127, 155);
    border-radius: 2px;
    color: azure;
    cursor: pointer;
}

.reverse-trans-btn:hover {
     opacity: 0.5;
}