

.users-analytic-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.stat-box-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
    flex-direction: column;
    background-color: #fee4f0;
    width: 340px;
    border-radius: 5px;
    color: #1a2d6e;
    margin-bottom: 20px;
}

.stat-box-wrap h1 {
    font-size: 37px;   
}

.stat-box-wrap p {
    font-size: 12px;   
}