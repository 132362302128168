.user-content-wrapper {
   display: flex;
   
}

.content-title-wrap {
    justify-content: space-between;
    align-items: center
}

.details-container {
    display: flex;
    margin-top: 20px
}

.user-details-wrapper {
    width: 55%;
    margin-bottom: 50px
}

.user-data-info {
    display: flex;
    padding: 10px 10px 20px 10px ;
    border-bottom: solid 1px #e7e7e7;
    
}

.user-data-info .info-title {
    margin-right: 30px;
    width: 150px
}

.user-data-info .info-response {
    color: #999
}

.user-identity-card-wrapper {
    text-align: right;
    width: 45%;
}

.user-identity-card-wrapper img {
    object-fit: contain;
    

}

.dialog-box-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: fixed;
    background: rgb(255, 255, 255);
    padding: 20px;
    height: 300px;
    border-radius: 5px;
    right: 40%;
    z-index: 1000000;
    top: 30%;
    width: 30%;
    border: 2px solid rgb(26, 26, 102);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;}

.dialog-box-container input{
    border: none;
    height: 35px;
    outline: none;
    margin: 30px;
    border-radius: 3px;
    padding-left: 10px;
    font-size: 18px;
    font-weight: 600
    
}

.dialog-box-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: center;
    
}

.dialog-box-header img {
    position: absolute;
    width: 30px;
    right: 20px;
    cursor: pointer;
}

.css-2b097c-container {
    width: 80%;
    margin-top: 50px;
    
}

.css-g1d714-ValueContainer {
    height: 50px;
}

.css-1uccc91-singleValue{
    top: 90% !important;
}

.placeholder {
    top: 50% !important;
}

.other-reason-input {
    border: 1px solid #7383b5 !important;
    width: 300px;
    height: 60px !important;
    margin-bottom: 10px;
    margin-top: 5px;
    border-radius: 4px
}