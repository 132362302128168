.user-content-wrapper {
   display: flex;
   
}

.content-title-wrap {
    justify-content: space-between;
    align-items: center
}

.details-container {
    display: flex;
    margin-top: 20px
}

.user-details-wrapper {
    width: 55%;
    margin-bottom: 50px
}

.user-data-info {
    display: flex;
    padding: 10px 10px 20px 10px ;
    border-bottom: solid 1px #e7e7e7;
    
}

.user-data-info .info-title {
    margin-right: 30px;
    width: 150px
}

.user-data-info .info-response {
    color: #999
}

.user-identity-card-wrapper {
    text-align: right;
    width: 45%;
}

.user-identity-card-wrapper img {
    object-fit: contain;
    

}

.dialog-box-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    background: #777;
    padding: 50px;
    height: 170px;
    border-radius: 5px;
    right: 10%;
    z-index: 1000000;
    top: 30%
}

.dialog-box-container input{
    border: none;
    height: 35px;
    outline: none;
    margin: 30px;
    border-radius: 3px;
    padding-left: 10px;
    font-size: 18px;
    font-weight: 600
    
}