.page-container {
  display: flex;
  flex-direction: column;
}

.content-wrapper {
  display: flex;
  flex-direction: row;
  margin-top: 6%
}

.main-content-wrapper {
  display: flex;
  width: 77%;
  position: relative;
  left: 18%;
  padding: 20px 20px 20px 40px; 
  justify-content: center;
  flex-direction: column;
  
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
